import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  EventMessage,
  AuthError,
} from "@azure/msal-browser";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { msalConfig } from "./authConfig.ts";
import { b2cPolicies } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  const payload = event.payload as AuthenticationResult;
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    payload.account
  ) {
    msalInstance.setActiveAccount(payload.account);
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    const error = event.error as AuthError;
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error && error.errorMessage.includes("AADB2C90118")) {
      const resetPasswordRequest = {
        authority: b2cPolicies.authorities.forgotPassword.authority,
        scopes: [],
      };
      msalInstance.loginRedirect(resetPasswordRequest);
    }
  }
});

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App instance={msalInstance} />
//     </BrowserRouter>
//   </React.StrictMode>
// );

root.render(<div>Under maintenance</div>);
